import axios from 'axios'

const SERVER_URL = 'api';

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000
});

export default {
    upload: (file, deleteExisting) => {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('deleteExisting', deleteExisting)
        return instance.post('/upload', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformResponse: [function (data) {
                    return data ? JSON.parse(data) : data
                },]
            })
    },

    getAllImportLog: () => instance.get('/importlog', {
        transformResponse: [function (data) {
            return data ? JSON.parse(data)._embedded.importLogs : data;
        },],
        params: {'sort': 'datetime,desc'}
    }),
}