<template>
    <div>
        <Error/>
        <Help>
            On this page you can import a CSV file in the database. Check the box "Delete existing data" to remove every
            existing sample and replace them by your file.<br>
            "Delete existing data" will not delete the Locus nor the locus order.<br>
            <v-alert dense type="error">Any change you do on that page will affect irreversibly the database.</v-alert>
            This is an example of the data that can be imported:
            <v-simple-table>
                <thead>
                <tr><td>Sample</td><td>Analyst</td><td>D3</td><td>Amel</td></tr>
                </thead>
                <tbody>
                <tr><td>DB0001</td><td>ME</td><td>17,17</td><td>X,X</td></tr>
                <tr><td>DB0002</td><td>YOU</td><td>13,15.3</td><td>X,Y</td></tr>
                </tbody>
            </v-simple-table>

            Which would be presented in a file as: <br>
            <code>Sample,Analyst,D3,Amel<br>
                DB0001,ME,"17,17","X,X"<br>
                DB0002,YOU,"13,15.3","X,Y"
            </code>
            <br>The save-as function of Excel or <a href="https://www.libreoffice.org">LibreOffice Calc</a> (recommended)
            should produce a compliant file, as long as you say you want it comma separated.
            <br>
        </Help>

        <v-file-input ref="file" v-model="file" accept="text/csv" label="File input"/>
        <v-switch label="Delete existing data" v-model="deleteExisting"/>
        <v-btn outlined color="primary" v-on:click="submitFile()">Send</v-btn>
        <v-container>
            <v-data-table :items="importLog" :headers="headers"/>
        </v-container>
    </div>
</template>

<script>
    import {default as uploadApi} from '@/apis/upload.js'
    import Error from '@/views/Error.vue'
    import Help from '@/views/Help.vue'

    export default {
        name: "Upload",
        data: function () {
            return {
                file: null,
                error: "",
                importLog: [],
                headers: [{'text': 'File name', value: 'filename', align: 'center'},
                    {'text': 'Status', value: 'status', align: 'center'},
                    {'text': 'Message', value: 'message', align: 'center'},
                    {'text': 'Time', value: 'datetime', align: 'center'}],
                requestId: null,
                deleteExisting: false,
            }
        },
        components: {Error, Help},
        mounted() {
            this.updateLog()
        },
        methods: {
            updateLog() {
                uploadApi.getAllImportLog().then(response => {
                    this.error = ""
                    this.importLog = response.data
                }).catch(error => {
                    this.$log.debug(error)
                    if (error.response === undefined) {
                        this.error = "There was a network error, you may have to logout/login again sorry."
                    } else
                        this.error = "Impossible to access the server."
                    this.$store.dispatch('updateUser')
                }).finally(() => this.loading = false)

            },
            submitFile() {
                uploadApi.upload(this.file, this.deleteExisting).then(response => {
                    this.error = ""
                    this.updateLog()
                    this.requestId = response.data.requestId
                    this.checkRequest()
                }).catch(error => {
                    this.$log.debug(error)
                    if (error.response === undefined) {
                        this.error = "There was a network error, you may have to logout/login again sorry."
                    } else
                        this.error = "Impossible to access the server."
                    this.$store.dispatch('updateUser')
                }).finally(() => this.loading = false)
            },
            checkRequest() {
                if (this.requestId !== null) {
                    const vm = this
                    setTimeout(function() {
                        vm.updateLog()
                        const itemFound = vm.importLog.find((val) => { return val.id === vm.requestId})[0]
                        if (itemFound !== undefined) {
                            if (itemFound.status === "SUCCESSFUL" || itemFound.status === "FAILED") {
                                vm.requestId = null
                            } else {
                                vm.checkRequest()
                            }
                        }
                    }, 3000)
                }
            }
        }
    }
</script>
